@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

.title {
  @apply text-2xl font-bold mb-8 mt-12 uppercase;
}

p {
  @apply text-sm;
}
