// print css
@media print {
  .blank-page {
    display: block !important;
  }
  nav,
  .print-action {
    display: none !important;
  }
  .print-section {
    display: block !important;
  }
  body {
    font-size: 14pt;
    margin: 0;
  }
}
ul {
  @apply list-disc list-inside;
}

ol {
  @apply list-decimal list-inside;
}

.single-bar-chart {
  @apply h-3 relative;
  width: 300px;
  background-color: #696969;
  .bar-progress {
    @apply h-full;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blank-page {
  @apply w-full hidden break-before-page;
}
